
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
@keyframes vc-slider-bullet-animation {
  from {
    transform: scale3d(0, 1, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

// Slider vars
$vc-slider-active-color: #fff;
$vc-slider-inactive-color: #666;

.slider {
  --swiper-preloader-color: #{$vc-color-g80};
  width: 100%;
  aspect-ratio: 1;

  &_button {
    cursor: pointer;
    display: flex;
    position: absolute;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 100%;
    z-index: 2;
    right: 4%;
    top: 80%;
    color: $vc-color-g0;

    &_next {
      padding-left: 0.5rem;
    }

    @include respond-to($sm-down) {
      display: none;
    }

    // @include respond-to($lg-up) {
    //   width: 3.75rem;
    //   height: 3.75rem;
    //   top: 83%;
    // }
  }

  :global(.swiper-pagination) {
    bottom: 0 !important;
    width: auto !important;
  }

  :global(.swiper-slide) {
    height: auto;
  }

  :global(.swiper-lazy:not(.swiper-lazy-loaded)) {
    display: none !important;
  }

  &_pagination {
    background-color: $vc-color-g100;
    display: flex;
    align-items: flex-end;
    justify-content: left;
    padding-top: 1.25rem;
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;

    &__entry {
      display: block;
    }

    &__bullet {
      width: 2.5rem;
      height: 0.125rem;
      margin: 0 0.25rem 0 0 !important;
      opacity: 40% !important;
      background: white !important;
      border: 0.056rem solid white !important;
      border-radius: inherit !important;
      margin: 0 0.25rem;

      &--active {
        opacity: 1 !important;
        height: 0.1875rem;
        background: white !important;
        border-color: white !important;
      }
    }
  }
}

// Styles for new design of campaign block
.campaignContainerSlider {
  background-color: $vc-color-g100;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;

  .slider {
    aspect-ratio: 0;

    &_pagination {
      display: flex;
      background-color: transparent;
      align-items: flex-end;
      justify-content: left;
      padding-top: 1.25rem;
      padding-left: 1.5rem;
      padding-bottom: 1.5rem;

      &__entry {
        display: block;
      }

      &__bullet {
        width: 6.25rem;

        // fixed sizes for bullet
        @include respond-to($sm-down) {
          width: 100%;
        }

        @include respond-to($md-only) {
          width: 3.5rem;
        }

        @include respond-to($lg-up) {
          width: 5rem;
        }

        //overwrites base classes for swiper bullets
        height: 0.1875rem;
        opacity: 1 !important;
        border-radius: 0 !important;
        background: $vc-slider-active-color !important;
        border: none !important;

        &--active {
          position: relative;
          background: $vc-slider-inactive-color !important;

          &:after {
            content: '';
            position: absolute;
            inset: 0;
            background: $vc-slider-active-color;
            border: 0;
            transform-origin: top left;
            transform-style: preserve-3d;
            animation: var(--vs-slider-animation-duration) linear 0s vc-slider-bullet-animation;
          }
        }
      }
    }
  }

  img {
    width: 50%;
  }

  img:hover {
    opacity: 1 !important;
  }

  .slider_pagination {
    width: 100%;
    z-index: 1;
    position: static;
    top: 85%;
    padding: 0 1.5rem 1.5rem 1.5rem;
    left: 50% !important;
    bottom: 100% !important;
    right: 0;

    &__bullet--active ~ .slider_pagination__bullet {
      // Scale down all items after the active bullet.
      transform: scale3d(1, 0.5, 1);
      transform-origin: bottom left;
      transform-style: preserve-3d;

      // Paint pending items as inactive
      background: $vc-slider-inactive-color !important;
    }
  }
}

@include respond-to($sm-down) {
  .slider {
    aspect-ratio: 0;
    background-color: $vc-color-g100;
  }

  .campaignContainerSlider {
    img {
      width: 100%;
    }
  }
}

@include respond-to($md-up) {
  .entriesWrapper {
    display: flex;
    position: relative;
  }

  .campaignContainer {
    position: relative;
    height: 0;
    padding-top: 37.5%;

    .slider {
      display: none;
    }

    .entriesWrapper {
      position: absolute;
      height: auto;
      padding: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;

      .blocks__swiper {
        .campaign__swiper {
          min-width: inherit;
        }
      }
    }

    .baseline {
      display: flex;
    }
  }

  .swiper__nav {
    display: none;
  }

  .campaignContainerSlider {
    .slider_pagination {
      width: auto;
      position: absolute;
      padding-left: 3.125rem;
    }
    img {
      aspect-ratio: 4 / 3;
    }
  }
}
