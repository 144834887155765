
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
@import '../skeleton-animation.module.scss';

.productCardSkeleton {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 340px;
  padding: 26px;
  align-items: center;
  background-color: white;

  @include respond-to($md-up) {
    padding: 1.5rem;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
  }

  .skeletonIcon {
    width: 28px;
    height: 28px;
    border-radius: 100%;
  }

  .skeletonImage {
    width: 170px;
    height: 170px;
  }

  .skeletonContent {
    width: 100%;
    display: grid;
    align-items: center;
    margin-top: 26px;
  }

  .skeletonTitle {
    height: 18px;
    max-width: 160px;
    width: 80%;
  }

  .skeletonText {
    margin-top: 10px;
    height: 10px;
    max-width: 110px;
    width: 60%;
  }

  .short {
    max-width: 50%;
    width: 80px;
  }

  .long {
    max-width: 70%;
    width: 130px;
  }

  .skeletonFooter {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
}
