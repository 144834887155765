
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.closeButton {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
  @include respond-to($lg-up) {
    top: 1.9rem;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 1.25rem;
    background-color: $vc-color-grey-dark;
    @include respond-to($lg-up) {
      width: 2px;
    }
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
