
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.spinner {
  width: 32px;
  margin: auto;

  svg {
    animation: rotate 0.5s infinite linear;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
  }
}
