
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.dialog {
  @include respond-to($sm-down) {
    border-radius: 0.75rem !important;
    width: 90%;
  }
}

.title {
  font-weight: bold;
  padding-right: 1.5rem;
  margin-bottom: 0.75rem;
  font-size: 1.125rem;
}
.content {
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.buttonwrap {
  display: flex;
  flex-flow: row nowrap;
}

.button {
  flex: 1 1;
  height: 2.5rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.125rem;
  font-weight: 700;
  font-family: HelveticaNowText, sans-serif;
  white-space: nowrap;
  transition: all 0.3s ease;

  &:nth-child(2) {
    margin-left: 0.75rem;
  }
}

@include respond-to($md-up) {
  .title {
    padding-right: 4rem;
  }
}
