
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.listPost {
  text-align: center;
  padding-bottom: 1.5rem;
  &__title {
    text-align: left;
    padding: 1.5rem 0 0.5rem 0;
    font-size: 1.5rem;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    text-align: left;

    &__item {
      display: inline-block;
      width: 16rem;
      margin: 0 0.5rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__cta {
    margin: 0.5rem auto 0 auto;
  }
}

@include respond-to($md-up) {
  .listPost {
    padding-bottom: 1rem;
    &__title {
      text-align: left;
      font-size: 2.5rem;
      padding: 1.875rem 0;
    }
    &__list {
      &__item {
        width: 20rem;
        margin: 0 1rem;
      }
    }
    &__cta {
      margin-top: 5rem;
    }
  }
}

@include respond-to($lg-up) {
  .listPost {
    &__title {
      padding: 2.5rem 0 1rem 0;
      font-size: 2.5rem;
    }

    &__list {
      overflow: visible;

      &__item {
        margin: 0 1.688rem;
        width: 25vw;
        max-width: 20rem;
        display: inline-flex;

        &__post {
          display: block;
          width: 100%;
        }
      }
    }

    &__cta {
      margin: 1rem auto 1.5rem;
    }
  }
}

@include respond-to($xl-up) {
  .listPost {
    &__list {
      overflow-x: unset;
    }
  }
}
