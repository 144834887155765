
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
$light-color: white;
$dark-color: $vc-color-white-off;

.sizeGuide {
  &__description {
    padding: 19px 0 17px 0;
    font-size: 16px;
    line-height: 22px;
  }

  &__table {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    font-size: 1rem;
    line-height: 1.5;

    &__row {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;

      &:nth-of-type(even) {
        background-color: $light-color;
      }

      &:nth-of-type(odd):not(.highlight-row):not(:first-child) {
        background-color: $dark-color;
      }

      &.highlight-row {
        background-color: rgba($vc-color-brand, 0.3);

        .highlight-cell {
          background-color: $vc-color-brand;
          color: white;
        }
      }
    }

    &__row:first-child {
      color: white;
      background-color: $vc-color-black-off;
      font-weight: bold;

      .sizeGuide__table__row__item {
        height: 43px;
        line-height: 43px;
        padding: 0 0.5em;
      }

      .sizeGuide__table__cell {
        padding: 12px 0.5em 13px 0.5em;
        overflow: hidden;
        text-overflow: ellipsis;
        border-left-color: white;

        &:first-child {
          border-left-color: $vc-color-black-off;
        }

        &:last-child {
          border-right-color: $vc-color-black-off;
        }
      }
    }

    &__row:not(:first-child) .sizeGuide__table__cell {
      word-break: break-word;
    }

    &__cell {
      flex-grow: 1;
      flex-basis: 0;
      padding: 2px 0.5em 3px 0.5em;
      text-align: center;
      border-right: 1px solid $vc-color-grey-light;
      border-bottom: 1px solid $vc-color-grey-light;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      line-height: 20px;

      &:first-child {
        border-left: 1px solid $vc-color-grey-light;
      }
    }
  }
}

@include respond-to($md-up) {
  .sizeGuide {
    &__description {
      padding: 20px 0 30px 0;
      font-size: 18px;
      line-height: 28px;
    }

    &__table {
      margin-bottom: 10px;
    }
  }
}
