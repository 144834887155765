
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.drawer {
  z-index: $modal-depth;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  display: flex;
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s linear, visibility 0.3s linear;

  &:focus {
    outline: none;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;

    .drawer-container {
      &.left {
        left: -100%;
      }

      &.right {
        right: -100%;
      }

      &.bottom {
        bottom: -100%;
      }

      &.top {
        top: -100%;
      }
    }
  }

  &-container {
    position: absolute;
    min-width: 20%;
    padding: 1.5rem;
    background-color: #ffffff;
    z-index: 1;
    box-shadow: 3px 0 3px 0 rgba(34, 60, 80, 0.2);
    transition: left 0.3s linear, right 0.3s linear, top 0.3s linear, bottom 0.3s linear;
    overflow-y: scroll;
    max-width: 75%;

    &.left {
      left: 0;
      height: 100%;
      border-radius: 0 0.5rem 0.5rem 0;
    }

    &.right {
      right: 0;
      height: 100%;
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &.bottom {
      bottom: 0;
      width: 100%;
      border-radius: 0.5rem 0.5rem 0 0;
    }

    &.top {
      top: 0;
      width: 100%;
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }

  &-backdrop {
    flex: 1;
    background-color: #000000;
    opacity: 0.2;
    visibility: visible;
    transition: opacity 0.3s linear, visibility 0.3s linear;

    &-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
}
