
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.toggle-button-container {
  display: flex;
  flex-flow: row nowrap;
  padding: 2px;
  background-color: #f5f6f8;
  border-radius: 0.5rem;

  .toggle-button {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 0.8125rem 1rem 0.5rem 1rem;
    text-align: center;
    flex-grow: 1;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 45%;
      width: 1px;
      background-color: #dedede;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &:last-child:after {
      display: none;
    }

    &.toggle-button__active {
      background-color: #fff;
      box-shadow: 0 1px 2px rgb(0 0 0 / 16%);
      opacity: 1;
      border-radius: 0.5rem;
    }

    &.toggle-button__active:after {
      display: none;
    }

    .toggle-button-icon {
      display: flex;
      align-items: center;
      height: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .toggle-button-text {
      font-size: 0.75rem;
    }
  }
}
