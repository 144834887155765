
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.video {
  max-width: 872px;
  margin: 2rem auto;

  &_wrapper {
    position: relative;
    padding-bottom: 56.25%;
  }

  &_responsive {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
