
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
$cart-wh: 2.5rem;
$icon-wh: 1.5rem;

%cart-styles {
  width: $cart-wh;
  max-width: $cart-wh;
  height: $cart-wh;
  max-height: $cart-wh;
  padding: 1rem;

  @media (min-width: 768px) {
    width: 3.75rem;
    max-width: 3.75rem;
  }
}

.productCardFavActions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 0.75rem;
  width: 100%;
  align-self: flex-end;

  &:only-child .productCardFavActions__similar,
  &:first-child .productCardFavActions__mmao {
    flex-grow: 1;
  }

  &__cart {
    &__filled,
    &__atcLoading {
      @extend %cart-styles;
    }
    @extend %cart-styles;
  }

  &__similar {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__similar,
  &__mmao,
  &__cart {
    flex-basis: 100%;
    flex-shrink: 1;
    &:hover,
    &:focus,
    &:active {
      border-color: $vc-color-g20;
      outline: none;
    }

    &__disabled {
      cursor: not-allowed;
      border-color: $vc-color-g20;
    }

    &:disabled {
      cursor: not-allowed;
      border-color: $vc-color-g20;
    }

    &__atcLoading:disabled {
      background-color: white;
      cursor: not-allowed;
      border-color: $vc-color-g20;
      &:hover,
      &:focus,
      &:active {
        background-color: white;
        outline: none;
      }
    }

    border-color: $vc-color-g20;

    &__filled {
      flex-basis: 100%;
      flex-shrink: 1;

      &:hover,
      &:focus,
      &:active {
        outline: none;
      }

      & span {
        color: white;
      }
    }
    &__icon {
      display: block;
      width: $icon-wh;
      height: $icon-wh;

      & svg {
        width: $icon-wh;
        height: $icon-wh;
      }
    }

    &__loadingContainer {
      padding: 0 1.5rem;
      margin: 0;
      color: $vc-color-g20;
      outline: none;

      &__icon {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
