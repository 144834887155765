
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
@mixin imgcover {
  object-fit: cover;
}

$padding: 24px;

.visualcontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px $padding;
  row-gap: 1rem;

  &_img_module {
    width: 100%;
    display: flex;
    overflow: hidden;
    align-self: center;

    a {
      display: flex;
      position: relative;
      width: 100%;
      overflow: hidden;
    }

    &.portrait {
      max-width: 350px;
      max-height: 525px;
    }

    &.paysage {
      max-width: 350px;
      max-height: 262.5px;
    }

    &.cinema {
      max-width: 700px;
      max-height: 393.75px;
    }

    &.portraitCinema {
      max-width: 700px;
      max-height: 1244.44px;
    }

    &.wide {
      max-width: 700px;
      max-height: 300px;
    }
  }

  &_img_container {
    width: 100%;
  }

  &_title {
    text-align: center;
    padding-bottom: 16px;
  }

  .main_text {
    text-align: center;
    padding-bottom: $padding;
  }

  &_cta_module {
    &_cta {
      justify-content: center;

      a {
        width: 100%;
      }
    }
  }

  &_text_container {
    overflow-wrap: break-word;
    white-space: pre-line;
  }

  .visualcontainer_cta_module_cta + .visualcontainer_cta_module_cta {
    margin-top: 1rem;
  }

  &.imgAbove {
    flex-direction: column;

    .visualcontainer_img_module {
      padding: 0;
    }
  }

  &.imgBelow {
    flex-direction: column-reverse;

    .visualcontainer_text_module {
      padding-bottom: 1rem;
    }
  }
}

$gutter: 2rem;

@include respond-to($md-up) {
  $padding: 32px;

  .visualcontainer {
    width: 100%;
    padding: $padding 60px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    &_img_module {
      width: 50%;
      margin-bottom: 0;
      padding: 0 0 0 calc($gutter / 2);
      display: flex;

      &.portrait,
      &.paysage,
      &.cinema,
      &.portraitCinema,
      &.wide {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &_text_module {
      width: 50%;
      display: flex;
      padding: 0 calc($gutter / 2) 0 0;
      justify-content: center;

      .visualcontainer_text_container {
        width: 100%;
        max-width: 424px;
      }

      .main_text {
        text-align: left;
      }
    }

    &_title {
      text-align: left;
    }

    &_cta_module {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      &_cta {
        justify-content: left;
        width: 100%;
      }
    }
  }

  .imgLeft {
    flex-direction: row;

    .visualcontainer {
      &_img_module {
        padding: 0 calc($gutter / 2) 0 0;
      }

      &_text_module {
        padding: 0 0 0 calc($gutter / 2);
      }
    }
  }

  .imgAbove,
  .imgBelow {
    .visualcontainer {
      &_img_module,
      &_text_module {
        width: 100%;
      }
    }
  }
}

@include respond-to($lg-up) {
  .main_text {
    text-align: left;
  }

  .visualcontainer_img_module {
    &.portrait {
      padding-right: 20%;
    }
    &.paysage {
      padding-right: 3%;
    }
  }

  .imgLeft {
    .visualcontainer_img_module {
      padding-right: calc($gutter / 2);

      &.portrait {
        padding-left: 20%;
      }
      &.paysage {
        padding-left: 3%;
      }
    }
  }
}

@include respond-to($xl-up) {
  .visualcontainer {
    &_cta_module {
      &_cta {
        a {
          width: auto;
        }
      }
    }
  }
}
