
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.like {
  &__button {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    gap: 0;
    font-size: 14.56px;

    &:hover {
      // Color icon on hover
      .like__icon {
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 1;
        }
      }
    }
    &--textHidden {
      gap: 0.5rem;
      & .like__icon {
        width: 21px;
        height: 19px;
      }
    }
    &--favorite {
      gap: 0.3rem;

      & .like__icon {
        width: 24px;
        height: 22px;
        &::before {
          background-size: auto;
        }
        &::after {
          background-size: auto;
          background-image: url('#{$cdnFolder}/assets/images/icons/heart-full.svg');
        }
      }
    }
  }
  &__icon {
    display: flex;
    position: relative;
    width: 17px;
    height: 15px;

    &--semPdpIteration {
      width: 1.5rem;
      height: 1.5rem;
    }

    @include respond-to($md-up) {
      width: 24px;
      height: 23px;
    }
    // The trick is to replace heart-line image with heart-full image
    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.3s ease-in-out;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &::before {
      background-image: url('#{$cdnFolder}/assets/images/icons/heart-line.svg');
      opacity: 1;
      background-size: 21px 18px;
    }
    &::after {
      background-image: url('#{$cdnFolder}/assets/images/icons/heart-full.svg');
      opacity: 0;
      background-size: 21px 18px;
    }
    &--liked {
      animation: heartBeat 0.2s ease-out 1 forwards;
      &::before {
        opacity: 0;
      }
      &::after {
        background-image: url('#{$cdnFolder}/assets/images/icons/heart-full-red.svg');
        opacity: 1;
      }
    }
  }

  &__text {
    display: none;
    @include respond-to($lg-up) {
      display: flex;
    }
  }
}

@include respond-to($md-up) {
  .like {
    &__button {
      flex-direction: unset;
      gap: 0.3125rem;
      &--textHidden {
        gap: 0.5rem;
      }
    }
  }
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
