
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.title {
  font-weight: bold;
  margin-bottom: 1rem;
}
.content {
  max-height: 250px;
  overflow-y: auto;
}

.cta {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include respond-to($md-up) {
    margin-top: 1.5rem;
    flex-direction: row;
  }

  &__item {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include respond-to($md-up) {
      max-width: calc(50% - 0.5rem);
    }

    &:first-child {
      margin-bottom: 0.75rem;
      @include respond-to($md-up) {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }

    &--redirect {
      display: inline;
      text-align: center;
    }

    &__icon {
      height: 20px;
      width: 20px;
      margin-right: 0.5rem;
    }
  }
}
