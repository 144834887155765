
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.product_slider {
  &__product_card {
    height: calc(100% - 2px);
    outline: 1px solid $vc-color-g30;
  }

  &__swiper_slide {
    padding-top: 1px;
    height: auto;

    &:last-child {
      padding-right: 1px;
    }
  }

  &__swiper_wrap {
    margin: 0 -20px 0 -20px;

    & > div {
      padding-left: 1px;
      min-width: 100%;
    }
  }
}

@include respond-to($md-up) {
  .product_slider {
    &__swiper_wrap {
      position: relative;
      margin: 0;
    }

    &__swiper_button {
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      top: calc(50% + 0.75rem);
      margin-top: -31px;
      z-index: 1;
      background: #fff;
      border: 1px solid $vc-color-g90;
      padding: 0.5rem;
      transition: opacity 500ms ease-in-out;

      &--small {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;
        top: calc(57% + 0.75rem);
      }

      &:hover {
        background: $vc-color-g90;
        & > div {
          border-color: #fff;
        }
      }

      &__hide {
        opacity: 0;
      }
    }

    &__controls_icon {
      border-bottom: 1px solid $vc-color-g90;
      border-left: 1px solid $vc-color-g90;
      width: 10px;
      height: 10px;
    }

    &__prev_button {
      left: -10px;
    }

    &__prev_icon {
      transform: rotate(45deg);
      margin-left: 9px;

      &__hide {
        display: none;
      }
    }

    &__next_button {
      right: -10px;
    }

    &__next_icon {
      transform: rotate(-135deg);
      margin-left: 4px;
    }
  }
}
