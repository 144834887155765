
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
@mixin imgcover {
  object-fit: cover;
}

.campaign {
  &__container {
    display: flex;
    height: 100%;
  }

  &__a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  &__a &__img:hover {
    opacity: 0.8;
  }

  &__img {
    width: 100%;
    height: 100%;
    @include imgcover;

    &__container {
      display: block !important;
      min-width: 100%;
      height: 100%;
      transition: 0.3s ease-in-out;
      width: 100%;
      @include imgcover;
    }
  }

  &__title {
    display: block;
    position: absolute;
    z-index: 2;

    /* Current Design without slider */
    .campaign {
      &__h4 {
        font-size: 1.75rem;
        line-height: 1.875rem;
        font-family: $vc-font-headings;
        font-weight: 400;
        padding: 1rem 1rem 0 1rem;
        margin: 0;
        vertical-align: baseline;
        word-wrap: break-word;
        color: $vc-color-g0;
      }

      &__subtitle {
        display: block;
        padding: 0 1rem;
      }
      &__h5 {
        display: flex;
        align-items: center;
        font-family: $vc-font-body;
        color: $vc-color-g0;
        font-size: 1rem;
        padding: 0.5rem 1rem 1rem 1rem;
        font-weight: 500;
        margin: 0;
        vertical-align: baseline;
      }
      &__arrow {
        display: flex;
        position: relative;
        padding-left: 0.25rem;
        padding-top: 0.15rem;
        width: 2rem;
        height: 1.25rem;
        flex-shrink: 0;
      }
    }

    /* NEW Design with slider */
    .campaign__slider {
      &__h4 {
        font-family: $vc-font-headings;
        font-weight: 500;
        vertical-align: baseline;
        word-wrap: break-word;
        color: $vc-color-g0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-height: 6rem;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding: 0;
        margin: 0;
        flex-shrink: 0;
      }

      &__subtitle {
        font-family: $vc-font-body;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &__h5 {
        display: flex;
        align-items: center;
        font-family: $vc-font-body;
        color: $vc-color-g0;
        font-weight: 500;
        margin: 0;
      }

      &__arrow {
        display: flex;
        position: relative;
        padding-left: 0.25rem;
        padding-top: 0.15rem;
        width: 2rem;
        height: 1.25rem;
        flex-shrink: 0;
      }

      &__chip {
        /* hardcoded colors of chips for single campaign block*/
        --chipsField-hoverColor: #{darken($vc-color-g0, 90%)};
        --chipsField-activeColor: #{darken($vc-color-g0, 85%)};

        a {
          font-weight: 500;
          color: $vc-color-g0;
          border-color: $vc-color-g0;
          vertical-align: middle;
          margin: 0.5rem 0.5rem 0 0;
        }

        @include respond-to($sm-down) {
          margin: 0.5rem 0 1.5rem 0;
          -webkit-overflow-scrolling: touch;

          &::-webkit-scrollbar {
            display: none;
          }

          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          width: 100%;
          overflow-x: auto;

          &__wrapper {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0 1.5rem;
          }

          a {
            margin: 0.5rem 0 0 0;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.context {
  left: 10%;
  bottom: 10%;
  background: $vc-color-g100;
  color: $vc-color-g0;
  max-width: 80%;
}

@include respond-to($md-only) {
  .campaign__slider {
    &__h4 {
      font-size: 2rem;
      line-height: 2rem;
    }

    &__h5 {
      font-size: 1rem;
      padding-top: 1rem;
    }

    &__subtitle {
      margin-top: 0.5rem;
      font-size: 1rem;
      line-height: 1.5;
    }

    &__chip {
      &__wrapper {
        padding-top: 0.5rem;

        // We limit the number of chips to three lines for breakpoints between 768px and 1024px, following the design
        max-height: 9.5rem;
        overflow: hidden;
      }

      a {
        font-size: 0.875rem;
      }
    }
  }
}

@include respond-to($md-up) {
  /* NEW Design with slider */
  .context_slider {
    background-color: $vc-color-g100;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    top: 8.5%;
    width: 50%;
    left: 50%;
    padding: 0 2.5rem;
  }

  .campaign {
    &__title {
      .campaign {
        &__h5 {
          padding-top: 0.25rem;
        }
      }
    }
    &__img {
      -webkit-transform: none;
      transform: none;
      position: static;
      width: 100%;
      @include imgcover;

      &__container {
        -webkit-transform: none;
        transform: none;
        position: static;
        width: 100%;
        @include imgcover;
      }
    }
  }
}

@include respond-to($sm-down) {
  .context {
    position: static;
    background: $vc-color-g100;
    color: $vc-color-g0;
    max-width: 100%;
  }
  .contextXL {
    right: 0;
  }
  .campaign {
    &__img {
      &__container {
        height: 75vw;
      }
    }

    &__title {
      width: 100%;

      .campaign {
        &__h4 {
          padding: 1rem 1.5rem 0 1.5rem;
        }

        &__subtitle {
          padding: 0 1.5rem;
        }
        &__h5 {
          padding: 0.5rem 1.5rem 0 1.5rem;
        }
      }

      /* NEW Design with slider - Small */
      .campaign__slider {
        &__h4 {
          font-size: 1.75rem;
          line-height: 1.75rem;
          padding: 1rem 1.5rem 0 1.5rem;
        }

        &__h4,
        &__cta,
        &__subtitle {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &__subtitle {
          padding: 0.125rem 1.5rem 0 1.5rem;
          font-size: 1rem;
          line-height: 1.5;
        }

        &__h5 {
          font-size: 1rem;
          padding: 0.5rem 1.5rem 1.25rem 1.5rem;
        }
      }
    }

    &__a {
      justify-content: flex-start;
      flex-direction: column;
    }
  }
}

@include respond-to($lg-up) {
  .campaign__slider {
    &__h4 {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }

    &__h5 {
      font-size: 1.125rem;
      padding-top: 1.5rem;
    }

    &__subtitle {
      margin-top: 0.75rem;
      font-size: 1.125rem;
      line-height: 1.5;
    }

    &__chip {
      &__wrapper {
        padding-top: 1rem;
      }

      a {
        font-size: 1rem;
      }
    }
  }
}

@include respond-to($xl-up) {
  .context_slider {
    padding: 0 4rem;
  }

  .campaign__slider {
    &__h4 {
      font-size: 3rem;
      line-height: 3rem;
    }
  }
}
