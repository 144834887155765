
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.campaignHeroContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  width: 100%;

  &__fields {
    z-index: 1;
    width: 100%;
  }

  &__image {
    position: absolute;
    height: 100%;
  }

  &--voucher {
    padding: 1rem 0;
  }

  &--marketing_medium {
    padding: 1.5rem;
    margin: 0.5rem 0;
  }

  &--marketing_small {
    padding: 0;
    margin: 0.5rem 0;
  }

  &--pointer {
    cursor: pointer;
  }

  @include respond-to($md-up) {
    padding: 2.5rem 0;

    &--voucher {
      padding: 1.5rem 0;
    }

    &--marketing_medium {
      padding: 1.5rem;
      margin: 1.5rem 1.25rem;
      width: auto;
    }

    &--marketing_small {
      margin: 1.5rem 1.25rem;
      width: auto;
      padding: 0;
    }
  }
  @include respond-to($xl-up) {
    &--marketing_medium,
    &--marketing_small {
      margin: 1.5rem auto;
      width: 100%;
    }
  }
}
