
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.banner {
  position: absolute;
  height: 2.5rem;
  width: 100%;
  text-align: center;
  overflow: hidden;
  font-family: $vc-font-body;
}
