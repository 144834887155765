
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.entriesWrapper {
  display: block;
  position: relative;
}

.merchandisingContainer {
  .entriesWrapper {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    padding-top: 2.5rem;
    padding-bottom: 1.25rem;
    padding-bottom: 2.5rem;
    .container {
      &__merchandising {
        width: 100%;
      }
    }
  }

  ul {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    @include respond-to($md-up) {
      gap: 1rem;
    }
  }
}
@include respond-to($sm-down) {
  .merchandisingContainer {
    .entriesWrapper {
      padding-bottom: 1.5rem;
      padding-top: 0.25rem;
      .container {
        &__merchandising {
          width: 100%;
        }
      }
    }
  }
  .component_title {
    font-size: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 0.25rem;
    color: $vc-color-g100;

    // Ellipsis
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.3;
  }
}

@include respond-to($sm-up) {
  .merchandisingContainer {
    .entriesWrapper {
      justify-content: flex-start;

      &.wrapperMerch-1,
      &.wrapperMerch-2 {
        justify-content: center;
      }
    }
  }
}

@include respond-to($md-up) {
  .entriesWrapper {
    display: flex;
    position: relative;
  }

  .merchandisingContainer {
    .entriesWrapper {
      padding-top: 0.5rem;
      &.wrapperMerch-5 {
        justify-content: flex-start;
      }

      &.wrapperMerch-6 {
        justify-content: flex-start;
      }
    }
  }

  .component_title {
    padding-top: 2.5rem;
    padding-bottom: 0.5rem;
    color: $vc-color-g100;
    font-size: 2.5rem;

    // Ellipsis
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.3;
  }
}

@include respond-to($lg-up) {
  .merchandisingContainer {
    .entriesWrapper {
      justify-content: space-between;

      ul {
        display: flex;
        width: 100%;
        justify-content: space-around;
      }

      &.wrapperMerch-6,
      &.wrapperMerch-5 {
        justify-content: space-between;
      }
    }
  }
}
