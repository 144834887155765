
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
@mixin imgcover {
  object-fit: cover;
}

$radius: 4px 4px 0 0;
$descPadding: 16px;
$descPadding2: $descPadding * 2;

%textarea {
  a,
  a span {
    color: #de8962;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  img {
    display: inline;
    margin: 0 5px;
    padding: 0;
  }

  .marker {
    background-color: #ffd900;
  }
}

.cta {
  cursor: pointer;
}

.innerContainer {
  max-width: 1460px;
  margin: 0 auto;
}

.description {
  &__text {
    position: relative;
    left: $descPadding;
    margin-top: -$descPadding2;
    margin-bottom: $descPadding;
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    background-color: #ffffff;
    border-radius: $radius;
    text-align: center;

    h1 {
      font-weight: 300;
      font-family: $quarto;
      font-size: 2rem;
      line-height: normal;
      padding: $descPadding $descPadding2;
    }
  }

  &__description {
    font-family: $vc-font-body;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $vc-color-primary;
    padding: $descPadding $descPadding 8px;
    font-weight: normal;

    @extend %textarea;
  }

  &__image {
    line-height: 0;
    img {
      width: 100%;
      height: 160px;
      @include imgcover;
    }
  }

  &__container {
    padding: 0;
    position: relative;

    .cta-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 8px;

      .cta {
        display: flex;
        margin: $descPadding;
        font-size: 0.75rem;
        line-height: 0.8rem;
        letter-spacing: 1.1px;
        color: $vc-color-primary;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .description {
    &__container {
      &__text {
        left: $descPadding2;
        width: calc(100% - 64px);

        h1 {
          font-size: 2.2rem;
          line-height: normal;
          padding: $descPadding2 $descPadding2 $descPadding;
        }
      }
    }

    &__image {
      img {
        height: 100%;
        min-height: 384px;
      }
    }
  }
}

@include respond-to($lg-up) {
  .section {
    border-bottom: 1px solid $vc-color-white-off;
  }

  .description {
    &__container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }

    &__text {
      display: flex;
      width: 50%;
      margin: 0;
      left: 0;
      border-radius: 0;
      text-align: left;
      padding: 4px;
      .cta-container {
        justify-content: left;
        padding: 0 $descPadding 0 $descPadding2;
        .cta {
          margin: $descPadding $descPadding 0 0;
        }
      }
    }

    &__description {
      padding: 0 $descPadding 8px $descPadding2;
    }

    &__image {
      display: flex;
      width: 50%;
      margin: 0;
      height: 360px;

      img {
        height: 100%;
        min-height: 324px;
        @include imgcover;
      }
    }
  }
}

@include respond-to($xl-up) {
  .description {
    &__text {
      padding: 0 $descPadding 0 52px;
    }

    &__description {
      font-size: 18px;
      h1 {
        font-size: 36px;
        padding: $descPadding $descPadding2;
      }
    }

    &__image {
      img {
        min-height: 360px;
      }
    }
  }
}
