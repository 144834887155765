
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.sizeGuide {
  &__modalContent {
    margin-top: 0;
    padding-bottom: 70px;
  }

  &__title {
    padding-bottom: 18px;
    border-bottom: 1px solid $vc-color-grey-light;
  }
}

@include respond-to($md-up) {
  .sizeGuide {
    &__modalContent {
      padding-bottom: 0;
    }

    &__title {
      padding-bottom: 0;
      border: none;
    }
  }
}
