
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.toastMessage {
  position: sticky;
  bottom: 1.5rem;
  margin-left: auto;
  margin-right: 1rem;
  z-index: $modal-depth;
  background-color: white;
  border: 1px solid $vc-color-grey-light;
  border-radius: 2px;
  box-shadow: 6px 2px 32px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  max-width: 25rem;
  min-width: 10rem;
  width: 100%;

  @include respond-to($md-down) {
    bottom: 5.5rem;
  }

  @include respond-to($sm-down) {
    bottom: 5.5rem;
    width: 91%;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    &__label {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      color: $vc-color-g100;

      @include respond-to($sm-down) {
        & span {
          line-height: 1.5;
        }
      }
    }
    &__icon {
      height: 1.5rem;
      width: 1.5rem;
      color: $vc-color-g100;
      flex-shrink: 0;

      &--success {
        color: $vc-color-system-green60;
      }

      &--info {
        color: $vc-color-orange-60;
      }

      &--warning,
      &--error {
        color: $vc-color-system-red60;
      }
    }
    &__close {
      cursor: pointer;
      height: 1rem;
      width: 1rem;
      flex-shrink: 0;
      align-self: flex-start;
      margin-top: 0.25rem;
    }
  }
}
