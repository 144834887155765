
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.footer {
  border-top: 1px solid #ebebeb;
  overflow: hidden;

  .footerContent {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .international {
    color: #656565;
    font-size: 0.875rem;
  }

  .guide {
    text-decoration: underline;
    font-size: 0.875rem;
    color: #1e1e1e;
    font-weight: 600;
  }
}

.footerBtn {
  min-width: 7.875rem;
  border-radius: unset;
}

.checked {
  border: 1px solid #1e1e1e;
  background: #1e1e1e;
  color: #fff;
}

.unchecked {
  border: 1px solid #dedede;
  background: 0 0;
  color: #1e1e1e;
}

@include respond-to($sm-down) {
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    box-sizing: border-box;
    background-color: #fff;
    padding: 0 1.5rem;
    z-index: 1;
    .footerContent {
      padding: 1rem 0;
      display: block;
    }
  }

  .footerItem1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .footerBtn {
    width: 100%;
  }
}
