
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.progressBar--hidden {
  visibility: hidden;
}

.wizard {
  display: block;

  .header {
    width: 100%;
    height: 18px;
    margin-bottom: 24px;

    // grid
    display: grid;
    grid-template-columns: 1fr 330px 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    align-items: center;

    & .progressBar {
      text-align: center;
    }

    & .right {
      text-align: right;
    }
  }
}
